<template>
  <b-tabs type="is-toggle" v-model="activeTab" @input="changeTab">
    <b-tab-item
      v-for="tab in filteredTabs"
      :key="tab.key"
      :value="tab.key"
      :label="tab.name"
    ></b-tab-item>
  </b-tabs>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      activeTab: 'overview',
    }
  },
  computed: {
    ...mapState('addyPlusBase', ['permissions']),
    tabs() {
      const { accountId, corporationId } = this.$route.params
      return [
        {
          key: 'overview',
          name: 'Overview',
          path: `/addyPlus/${accountId}/corporations/${corporationId}`,
          permission: 'entity',
        },
        {
          key: 'dataRoom',
          name: 'Data Room',
          path: `/addyPlus/${accountId}/corporations/${corporationId}/dataRoom`,
          permission: 'entityDataRoom',
        },
        {
          key: 'properties',
          name: 'Properties',
          path: `/addyPlus/${accountId}/corporations/${corporationId}/properties`,
          permission: 'asset',
        },
        {
          key: 'transactionLedger',
          name: 'Transaction Ledger',
          path: `/addyPlus/${accountId}/corporations/${corporationId}/transactionLedger`,
          permission: 'entityTransactionLedger',
        },
        {
          key: 'csr',
          name: 'CSR',
          path: `/addyPlus/${accountId}/corporations/${corporationId}/csr`,
          permission: 'entityCsr',
        },
        {
          key: 'executives',
          name: 'Directors, Officers & Signatories',
          path: `/addyPlus/${accountId}/corporations/${corporationId}/executives`,
          permission: 'entityExecutives',
        },
        {
          key: 'wallet',
          name: 'Wallet',
          path: `/addyPlus/${accountId}/corporations/${corporationId}/wallet`,
          permission: 'entityWallet',
        },
        {
          key: 'distributions',
          name: 'Distributions',
          path: `/addyPlus/${accountId}/corporations/${corporationId}/distributions`,
          permission: 'entityDistributions',
        },
      ]
    },
    filteredTabs() {
      return this.tabs.filter((tab) => {
        return (tab.permission ? !!this.permissions?.[tab.permission]?.includes('read') : false)
      })
    },
  },
  created() {
    this.setActiveTab()
  },
  methods: {
    setActiveTab() {
      const path = this.$route.path
      const corporationId = this.$route.params.corporationId
      const route = path.split(corporationId)[1]
      if (route) {
        const firstSegment = route.split('/')[1]
        this.activeTab = firstSegment
      }
    },
    changeTab(key) {
      this.$router.push(this.tabs.find((tab) => tab.key === key).path)
    }
  }
}
</script>
<style lang="scss" scoped>
.b-tabs ::v-deep .tabs.is-toggle li.is-active a span {
  color: #fff;
}
</style>
